import { createSlice } from '@reduxjs/toolkit';
import {
  getChatHistory,
  sendMessage,
} from '../routes/ChatAction';

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userToken,
  error: null,
  success: false,
  successMessage: '',

  newChat: 0,
  currentType: '',
  currentId: 0,

  chats: null,

  chat: null,

  newMessages: [],
}

// initialState.blockedUsers = [1,2,6,7];
initialState.newMessages = [
  {chatId: 0, times: 0}
];

export const chatSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
      setNewChatcount: (state, action) => {
        // console.log(action.payload);
        let newState = { ...state };
        let newValue = action.payload;
        newState.newChat = newValue;

        return newState;
      },
      setCurrentType: (state, action) => {
        // console.log(action.payload);
        let newState = { ...state };
        let newValue = action.payload;
        newState.currentType = newValue;

        return newState;
      },
      setCurrentId: (state, action) => {
        // console.log(action.payload);
        let newState = { ...state };
        let newValue = action.payload;
        newState.currentId = newValue;

        return newState;
      },

      selectChat: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.chats.map( el => el.id ).indexOf(action.payload);
        newState.chat = newState.chats[index];

        // fetch message here
        return newState;
      },

      selectAlert: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.alerts.map( el => el.id ).indexOf(action.payload);
        newState.alert = newState.alerts[index];

        return newState;
      },

      selectNotification: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.notifications.map( el => el.id ).indexOf(action.payload);
        newState.notification = newState.notifications[index];

        // fetch message here
        return newState;
      }
    },

    extraReducers: {
      
      // sendMessage
      [sendMessage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [sendMessage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [sendMessage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getChatHistory
      [getChatHistory.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getChatHistory.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.chats = payload.chats;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getChatHistory.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // end
    },

  })

// Action creators are generated for each case reducer function
export const { selectChat, selectAlert, selectNotification, setNewChatcount, setCurrentId, setCurrentType } = chatSlice.actions

export default chatSlice.reducer