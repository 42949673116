import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './ChatSlice'

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

const persistConfig = {
  key: "ai-root",
  version: 1,
  storage: storage,
};

const reducer = combineReducers({
  chat: chatReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});