import "./App.css"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"

import Chat from "./home/Chat"

function App() {

  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Chat />} />

        <Route exact path='/chat' element={<Chat/>} />
        
        <Route path="*" element={<Chat/>} />
      </Routes>
    </Router>
  );
}

export default App
