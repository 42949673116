import React, { useState, useEffect, useRef } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getChatHistory, sendMessage, } from "../routes/ChatAction";

import { compDate, reduceText, timeFormat, timeTable, timeFormatNow } from "../dummyData";
import "./chat.css"

const Chat = () => {

    const dispatch = useDispatch();
    const search = useLocation().search;
    const id =new URLSearchParams(search).get("id");
    const type =new URLSearchParams(search).get("type");

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    const [chatData, setChatData] = useState([]);
    const [newSentMsg, setNewSentMsg] = useState('');
    const [unreadMessage, setUnreadMessage] = useState(false);
    const [phone, setPhone] = useState('');


    const fetchChats = () => {
        if (phone !== '') {
            dispatch(getChatHistory(phone))
            .then(response =>{
                setNewSentMsg('')
                let resData = response.payload.data;
                setChatData(resData);
            })
            .catch((error) => {
                console.log(error)
            })
        } else {

        }
    }

    useEffect(() => {
        fetchChats();
    
    }, [id, type, phone])

    const triggerRefresh = () => {
        fetchChats();
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
            // alert(`Enter key pressed!`);
        }
    };

    const messagesEndRef = useRef(null);
    const messagesEndRef2 = useRef(null);
    const moveDown = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        messagesEndRef2.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const [textField, setTextField] = useState("");
    const handleTextFieldChange = (e) => {
        setTextField(e.target.value);
    }

    const handleSendMessage = () => {

        if (textField !== '' && phone !== '') {
            dispatch(sendMessage({
                msg: textField,
                type: 0,
                phone: phone,
            }))
            .then(response =>{
                setNewSentMsg(textField)
                setTextField('')
                triggerRefresh()
            })
            .catch((error) => {
                console.log(error)
            })
        }
        
    }

    const handlePhoneChange = (e) => {
        // Remove all non-digit characters
        let value = e.target.value.replace(/\D/g, '');
    
        // Add the '233' prefix if it's not there
        if (!value.startsWith('233')) {
          value = '233' + value;
        }
    
        // Trim the value to ensure it only has a total of 12 digits (including the '233' prefix)
        if (value.length > 12) {
          value = value.substring(0, 12);
        }
    
        setPhone(value);
    };
    
    return(
        <>

            <div className="mt-4 pt-2">

                <div>
                    <div className='row ml-4 mr-1'>
                        
                        {/* <div className='col-6 col-lg-3 col-md-3'>
                            <div className="input-group input-group-sm my-1">
                                <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                                    <i className="fa fa-envelope"></i>
                                </span>
                                <select 
                                    className="form-control form-select"
                                    value={viewOption}
                                    onChange={handleChangeView}
                                    style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="city" id="city" autoComplete="city" placeholder="City"
                                >
                                    <option value=''>Select</option>
                                    <option value='chat'>Chats</option>
                                </select>
                            </div>
                        </div> */}
                        <div className='d-flex justify-content-center'>
                            {/* <div className="input-group input-group-sm col-3 my-1">
                                <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                                    <i className="fa fa-envelope"></i>
                                </span>
                                <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="date" name="dob" id="dob" autoComplete="dob" className="form-control" placeholder="Phone no"></input>
                            </div> */}
                            <div className="input-group input-group-sm my-1" style={{width: '200px'}}>
                                <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                                    <i className="fa fa-phone"></i>
                                </span>
                                <input value={phone} onChange={handlePhoneChange} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="phone" id="phone" autoComplete="phone" className="form-control" placeholder="Phone no"></input>
                            </div>
                        </div>
                    
                    </div>
                </div>

                {/* <CustomAlert ref={customAlertRef}/> */}

                <div className="mx-auto b-screen">
                    <div className="d-flex justify-content-center mx-3" style={{}}>
                        {/* right side history */}
                        {/* <div className="col-3 mx-1 overflow-y-auto" style={{height:'80vh'}}>
                             
                            <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No chat history</h5>
                        </div> */}
                        
                        
                        <div className="col-7 d-flex flex-column">
                            <div style={{border:'2px solid #16307D', height:'', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2">
                                <div className="d-flex flex-row justify-content-between mx-1 my-1">
                                    <h6 style={{color:'#16307D', fontSize:14}} className="my-1">{phone}</h6>
                                    
                                    {/* toggles */}
                                    <div className="d-flex flex-row justify-content-between">
                                        <span onClick={() => moveDown()} style={{backgroundColor:`${unreadMessage? 'red': '#5FF15C'}`, border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-arrow-down"></i>
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>

                            {/* chat msgs */}
                            <div style={{border:'2px solid #16307D', height:'60vh', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2 overflow-y-auto" id="move-chat-div2">

                            {chatData !== null? <>
                                {/* <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                    {chatData?.phone} started this chat at {timeFormat(chatData?.created_at)}...
                                </h6> */}


                                {chatData?.length > 0 ?
                                    chatData?.map((row, index) => (
                                        <div key={row?.id}>
                                            <>
                                            {
                                                index === 0? 
                                                    <div className="d-flex my-1">
                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row?.created_at)}</h6>
                                                    </div>
                                                :
                                                <>
                                                    {
                                                        compDate(row?.created_at, chatData[index - 1]?.created_at) !== true?
                                                        <div className="d-flex my-1">
                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row?.created_at)}</h6>
                                                        </div>: <></>}
                                                </>
                                            }
                                            </>

                                            <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                <img alt='ddk' src="./assets/images/1.jpg" className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                    <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                        {row?.status === 9?
                                                            row?.subject 
                                                        :
                                                            'Not supported message'
                                                        }

                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start">
                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row?.created_at)}</h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-row justify-content-end mb-1" style={{paddingLeft:'20%'}}>
                                                <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                    <div style={{color:'#16307D', fontSize:12, backgroundColor:'#67BEFA', border:'1px solid #67BEFA'}} className="my-1 p-2 rounded-top">
                                                        {row?.status === 9?
                                                            row?.msg 
                                                        :
                                                            'Not supported message'
                                                        }
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-end">
                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row?.created_at)}</h6>
                                                    </div>
                                                    
                                                </div>
                                                <img alt='ddk' src="./assets/images/1.jpg" className="rounded-circle mt-auto mb-4 ml-1" height="30" width="30"></img>
                                            </div>

                                        </div>
                                    ))

                                    :

                                    <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No chat yet</h6>
                                }

                                {newSentMsg? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                    <img alt='ddk' src="./assets/images/1.jpg" className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                    <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                        <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #16307D'}} className="my-1 p-2 rounded-top">
                                            {newSentMsg}

                                        </div>
                                        <div className="d-flex flex-row justify-content-start">
                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                        </div>
                                    </div>
                                </div>: null}
                                </>: <>
                                    <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">Welcome ...</h6>
                                </>}
                                
                                <div ref={messagesEndRef} />
                            </div>

                            {/* send msg */}
                            <div style={{border:'2px solid #16307D', height:'11%', backgroundColor:'#F1F9FF'}} className="rounded-2 my-1">
                                <div className="d-flex flex-row justify-content-between">
                                    <div style={{width:'90%'}} className="d-flex flex-row justify-content-between">
                                        <span onClick={() => handleSendMessage()} style={{backgroundColor:'#5FF15C', border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-paper-plane"></i>
                                        </span>
                                        <input value={textField} onKeyPress={handleKeyPress} onChange={handleTextFieldChange} style={{width:'90%'}} className="form-control my-1" placeholder="Type here"></input>
                                    </div>

                                    <div className="d-flex flex-row justify-content-between">
                                        
                                        <span onClick={() => moveDown()} style={{backgroundColor:`${unreadMessage? 'red': '#5FF15C'}`, border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-arrow-down"></i>
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                        {/* right side details */}
                        {/* <div style={{border:'2px solid #16307D', height:"80vh"}} className="col-2 d-flex flex-column px-1 mx-1 my-1 rounded-2 overflow-y-auto">
                            

                        </div> */}

                    </div>
                </div>


                {/* mobile start */}

                <div className="px-auto m-screen">
                    <div className="d-flex flex-column col-12" style={{}}>
                        
                        <div className="col-7 d-flex flex-column col-12">
                            <div style={{border:'2px solid #16307D', height:'', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2">
                                <div className="d-flex flex-row justify-content-between mx-1 my-1">
                                    <h6 style={{color:'#16307D', fontSize:14}} className="my-1">{phone}</h6>

                                    <div className="d-flex flex-row justify-content-between">
                                        <span onClick={() => moveDown()} style={{backgroundColor:`${unreadMessage? 'red': '#5FF15C'}`, border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 rounded-pill mx-1 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-arrow-down"></i>
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>

                            <div style={{border:'2px solid #16307D', height:'60vh', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2 overflow-y-auto" id="move-chat-div">

                                {chatData !== null? <>
                                    {/* <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                        {chatData?.phone} started this chat at {timeFormat(chatData?.created_at)}...
                                    </h6> */}
                                    

                                    {chatData?.length > 0 ?
                                        chatData?.map((row, index) => (
                                            <div key={row?.id}>
                                                <>
                                                {
                                                    index === 0? 
                                                        <div className="d-flex my-1">
                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row?.created_at)}</h6>
                                                        </div>
                                                    :
                                                    <>
                                                        {
                                                            compDate(row?.created_at, chatData[index - 1]?.created_at) !== true?
                                                            <div className="d-flex my-1">
                                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row?.created_at)}</h6>
                                                            </div>: <></>}
                                                    </>
                                                }
                                                </>

                                                
                                                <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                    <img alt='ddk' src="./assets/images/1.jpg" className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                    <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                        <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                            {row?.status === 9?
                                                                row?.subject 
                                                            :
                                                                'Not supported message'
                                                            }

                                                        </div>
                                                        <div className="d-flex flex-row justify-content-start">
                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row?.created_at)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-content-end mb-1" style={{paddingLeft:'20%'}}>
                                                    <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                        <div style={{color:'#16307D', fontSize:12, backgroundColor:'#67BEFA', border:'1px solid #67BEFA'}} className="my-1 p-2 rounded-top">
                                                            {row?.status === 9?
                                                                row?.msg 
                                                            :
                                                                'Not supported message'
                                                            }
                                                        </div>
                                                        <div className="d-flex flex-row justify-content-end">
                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row?.created_at)}</h6>
                                                        </div>
                                                        
                                                    </div>
                                                    <img alt='ddk' src="./assets/images/1.jpg" className="rounded-circle mt-auto mb-4 ml-1" height="30" width="30"></img>
                                                </div>

                                            </div>
                                        ))

                                        :

                                        <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No chat yet</h6>
                                    }

                                    {newSentMsg? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                        <img alt='ddk' src="./assets/images/1.jpg" className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                        <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                            <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #16307D'}} className="my-1 p-2 rounded-top">
                                                {newSentMsg}

                                            </div>
                                            <div className="d-flex flex-row justify-content-start">
                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                            </div>
                                        </div>
                                    </div>: null}
                                </>: 
                                    <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">Welcome ...</h6>}
                                
                                <div ref={messagesEndRef2} />
                            </div>

                            {/* send msg */}
                            <div style={{border:'2px solid #16307D', height:'10%', backgroundColor:'#F1F9FF'}} className="rounded-2 my-1">
                                <div className="d-flex flex-row justify-content-between">
                                    <div style={{width:'90%'}} className="d-flex flex-row justify-content-between">
                                        {/* <i className="fa fa-face-smile px-2 my-auto"></i> */}
                                        <span onClick={() => handleSendMessage()} style={{backgroundColor:'#5FF15C', border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-paper-plane"></i>
                                        </span>
                                        <input onKeyPress={handleKeyPress} value={textField} onChange={handleTextFieldChange} style={{width:'90%'}} className="form-control my-1" placeholder="Type here"></input>
                                    </div>

                                    <div className="d-flex flex-row justify-content-between">
                                        <span onClick={() => moveDown()} style={{backgroundColor:`${unreadMessage? 'red': '#5FF15C'}`, border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-arrow-down"></i>
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Footer/> */}
        </>
        
    )
}

export default Chat